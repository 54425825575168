import React, { Component, Fragment } from 'react';
import axios from "axios";
const config = require('../config.json');

export default class ConfigForm extends Component {
    constructor(props) {
      super(props);      
      this.state = {
        id   : "",
        refreshed : "",
        pwd1 : "",
        pwd2 : "",
        remain : null,
        formConfig : null,
        clkErr : 0
      };
      this.handleChangeState = this.handleChangeState.bind(this);
      this.handleChangeRemain = this.handleChangeRemain.bind(this);
      this.handleChangeConfig = this.handleChangeConfig.bind(this);
      this.passwordState = this.passwordState.bind(this);      
    }
      
    passwordState() { // 0 = No password present, 1 = Invalid, 2 = Valid
      const valid = ((this.state.pwd1.length>0)||(this.state.pwd2.length>0)) ?
        (((this.state.pwd1 !== this.state.pwd2)||(this.state.pwd1.length<8)) ? 1 : 2) : 0;
      return valid;
    }

    handleChangeState(event) { // Used for password and confirm. Always text
      const target = event.target;
      let value = target.value;
      const name = target.name;
      this.setState({[name]: value});  
    }

    handleChangeRemain(event) { // Used for remaining time and update flag. Checkbox or number
      if (this.state.remain !== null) { // Check if its there
        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 :0) : target.value;
        const name = target.name;
        const us = name.indexOf("_");
        let tName = name;
        let i = -1;
        if (us >= 0) {
          tName = name.substring(0,us);
          i = name.substring(us+1,name.length);
        }
        if ((tName === "remain") && (value > 6000)) value = 6000;
        if ((tName === "remain") && (value < 0)) value = 0;  
        let newArray = [...this.state.remain];
        newArray[i] = {...newArray[i], [tName]: value};
        this.setState({ remain: newArray });
      }
    }

    handleChangeConfig(event) {
      if (this.state.formConfig !== null) {
        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 :0) : 
          (target.type === 'radio' ? Number(target.value) : 
          target.value);
//          (target.type === 'number' ? Number(target.value) : target.value));
        const name = target.name;
        const us = name.indexOf("_");
        const tName = (us >= 0) ? name.substring(0,us) : name;
        const i = (us >= 0) ? name.substring(us+1,name.length) : -1;
        if ((tName === "tpp") && (value > 6000)) value = 6000;
        if ((tName === "tpp") && (value < 0)) value = 0;
        if ((tName === "brt") && (value > 7)) value = 7;
        if ((tName === "brt") && (value < 0)) value = 0;
        if ((tName === "mpg") && (value > 5000)) value = 5000;
        if ((tName === "mpg") && (value < 0)) value = 0;  
        if (i===-1) {
          this.setState({ formConfig: {...this.state.formConfig, [tName]: value }});  
        } else {
          let newArray = [...this.state.formConfig.ch];
          newArray[i] = {...newArray[i], [tName]: value};
          this.setState({ formConfig: {...this.state.formConfig, ch: newArray }});
        } 
      }
    }
    
    handleCancel() {
      this.fetchConfig(true);
    }

    handleSend() {
      let chd = this.state.formConfig.ch.map((element,i) => {
        let ch = {
          fmt: element.fmt,
          en : element.en,
          png: element.png,
          tpp: element.tpp
        };
        if (this.state.remain[i].updateRemain) ch.rem = this.state.remain[i].remain;
        return ch;
      });
      
      let newData = {
        id : this.props.id,
        ch : chd,
        mpg: this.state.formConfig.mpg,
        brt: this.state.formConfig.brt
      }
      if(this.passwordState()===2) {
        newData.up = this.state.pwd1;
      }

      if (this.props.auth.isAuthenticated && this.props.auth.user) {
        const data = { tx : newData };
        axios
          .post(config.api.invokeUrl, data)
          .then((response) => {
            this.props.refreshParent();
          })
          .catch((error) => {
            console.log(error);
        });
      }
    }
    
    fetchConfig = async (resetPending) => {    
      if (this.props.auth.isAuthenticated && this.props.auth.user) {
        const data = { 
          "timer" : this.props.id,
          "resetpending" : resetPending
        };
        axios
          .post(config.api.invokeUrl, data)
          .then((response) => {
            if (resetPending) {
              this.props.refreshParent();
            }

            let data = response.data;
            const now = new Date(); 
            const tErr = data.now - now.getTime();
            let newconfig = {...data.config};
            let remain = [];
            for (let i = 0; i < data.config.ch.length; i++) 
              remain.push({updateRemain : 0, remain : 0});  
            let password = "";

            if (data.pending) {
              let pdata = data.pendingdata;
              if (typeof pdata.brt !== 'undefined') newconfig.brt = pdata.brt;
              if (typeof pdata.mpg !== 'undefined') newconfig.mpg = pdata.mpg;
              if (typeof pdata.up  !== 'undefined') password   = pdata.up;
              if (typeof pdata.ch  !== 'undefined') {
                for (let i = 0; i < pdata.ch.length; i++) {
                  if (typeof pdata.ch[i].fmt  !== 'undefined') newconfig.ch[i].fmt = pdata.ch[i].fmt;
                  if (typeof pdata.ch[i].en   !== 'undefined') newconfig.ch[i].en  = pdata.ch[i].en;
                  if (typeof pdata.ch[i].png  !== 'undefined') newconfig.ch[i].png = pdata.ch[i].png;
                  if (typeof pdata.ch[i].tpp  !== 'undefined') newconfig.ch[i].tpp = pdata.ch[i].tpp;
                  if (typeof pdata.ch[i].rem  !== 'undefined') {
                    remain[i].updateRemain = 1;
                    remain[i].remain = pdata.ch[i].rem;
                  }
                }
              }
            }

            this.setState({
              id         : this.props.id, 
              pwd1       : password,
              pwd2       : password,
              formConfig : newconfig, 
              refreshed  : data.refreshed,
              remain     : remain,
              clkErr     : tErr});
            })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    
    updateTimes = async () => {    
      if (this.props.auth.isAuthenticated && this.props.auth.user) {
        const data = { 
          "timer" : this.props.id,
          "resetpending" : false
        };
        axios
          .post(config.api.invokeUrl, data)
          .then((response) => {
            const now = new Date(); 
            const tErr = response.data.now - now.getTime();
            let newconfig = {...this.state.formConfig};
            let data = response.data.config;
            newconfig.sot = data.sot;
            if (typeof data.ch  !== 'undefined') {
              for (let i = 0; i < data.ch.length; i++) {
                if (typeof data.ch[i].tot  !== 'undefined') newconfig.ch[i].tot = data.ch[i].tot;
                if (typeof data.ch[i].ttp  !== 'undefined') newconfig.ch[i].ttp = data.ch[i].ttp;
                if (typeof data.ch[i].ntp  !== 'undefined') newconfig.ch[i].ntp = data.ch[i].ntp;
              }
            }
            this.setState({
              formConfig : newconfig, 
              refreshed  : response.data.refreshed,
              clkErr     : tErr});
            })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    refresh = async () => {  
      this.forceUpdate(); 
    }

    componentDidMount = () => {
      this.fetchConfig(false);
      setInterval(this.refresh, 1000);     // rerender the display every second
      setInterval(this.updateTimes, 5000); // runs every 5 seconds
    }

    ttpString = (t) => {
      const ts = t / 1000;
      var outstr = "";
      var dy  = Math.floor(ts / (24*3600));
      if (dy > 0) outstr = outstr + dy + "d";
      var hr  = (Math.floor(ts / 3600) % 24) + "";
      while(hr.length < 2) hr = "0" + hr;
      outstr = outstr + hr + "h";      
      var min = (Math.floor(ts /   60) % 60)+ "";
      while(min.length < 2) min = "0" + min;
      outstr = outstr + min + "m";     
      const tsec = Math.floor(ts % 60);
      if (tsec > 0) {
        var sec =  tsec + "";
        while(sec.length < 2) sec = "0" + sec;
        outstr = outstr + sec + "s";  
      }    
      return outstr;
    }

    msToTimeString = (t,fmt) => {   
      const ts = t / 1000;   
      var hr, min, sec;
      if (ts > 0) { 
        var outstr = "";
        if (((fmt === 0)&&(ts >= 3600)) || (fmt === 1)) { // Use HH:MM format
          hr  = Math.floor(ts / 3600) + "";;
          while(hr.length < 2) hr = "0" + hr;
          outstr = hr + "h";      
          min = (Math.floor(ts /   60) % 60)+ "";
          while(min.length < 2) min = "0" + min;
          outstr = outstr + min + "m";     
        } else { // Use MM:SS format 
          min = Math.floor(ts / 60)+ "";
          while(min.length < 2) min = "0" + min;
          outstr = min + "m";     
          const tsec = Math.floor(ts % 60);
          sec =  tsec + "";
          while(sec.length < 2) sec = "0" + sec;
          outstr = outstr + sec + "s";  
        }
      } else {
        if (fmt === 1) { // Use HH:MM format
          outstr = "00h00m";
        } else { // Use MM:SS format 
          outstr = "00m00s";
        }
      }
      return outstr;
    }

    render() {
      const now = new Date();
      if (this.state.formConfig === null) return (<p>Loading...</p>);
      return (
      <Fragment>
      <form>
        <div className="columns">
          <div className="column">
            <div className="table-container">
              <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                <tbody>
                  <tr>
                    <td>Brightness:</td>
                    <td><input
                      className="wide" name="brt" type="number" min="0" max="7" 
                      value={this.state.formConfig.brt} onChange={this.handleChangeConfig} /></td>
                  </tr>
                  <tr>
                    <td>Min Pulse Gap (ms):</td>
                    <td><input
                      className="wide" name="mpg" type="number" min="0" max="5000" 
                      value={this.state.formConfig.mpg} onChange={this.handleChangeConfig} /></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="column">
            <div className="table-container">
              <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                <tbody>
                  <tr>
                    <td>New password:</td>
                    <td><input
                      className="wide" name="pwd1" type="password" 
                      value={this.state.pwd1} onChange={this.handleChangeState} /></td>
                  </tr>
                  <tr>
                    {(this.state.pwd1 !== this.state.pwd2) ?
                      <td className="is-danger">Confirm Password:</td> :
                      <td>Confirm Password:</td>
                    }                    
                    <td><input
                      className="wide" name="pwd2" type="password" 
                      value={this.state.pwd2} onChange={this.handleChangeState} /></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        <div className="table-container">
          <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
            <tbody>            
              <tr>
                <th className="has-text-centered" rowSpan="2">Chan</th>
                <th className="has-text-centered" rowSpan="2">Remain</th>
                <th className="has-text-centered" rowSpan="2">Times<br />Paid</th>
                <th className="has-text-centered" rowSpan="2">Time<br />Paid</th>
                <th className="has-text-centered" rowSpan="2">Enable</th>
                <th className="has-text-centered" rowSpan="2">Neg Edge</th>
                <th className="has-text-centered" colSpan="3">Format</th>
                <th className="has-text-centered" rowSpan="2">Minutes<br />per pulse</th>
                <th className="has-text-centered" colSpan="2">Overwrite</th>
              </tr>
              <tr>
                <th className="has-text-centered">HH:MM</th>
                <th className="has-text-centered">MM:SS</th>
                <th className="has-text-centered">Auto</th>
                <th className="has-text-centered">Set</th>
                <th className="has-text-centered">Minutes</th>
              </tr>
              {
              this.state.formConfig.ch.map((element,i) => 
                <tr key={i}>
                  <td className="has-text-centered">{i+1}</td>
                  <td className="has-text-centered">{this.msToTimeString(
                    (this.state.formConfig.ch[i].tot - this.state.formConfig.sot) -
                    (now.getTime() - this.state.refreshed) - this.state.clkErr
                    , this.state.formConfig.ch[i].fmt)}</td>
                  <td className="has-text-centered">{this.state.formConfig.ch[i].ntp}</td>
                  <td className="has-text-centered">{this.ttpString(this.state.formConfig.ch[i].ttp)}</td>
                  <td className="has-text-centered"><input type="checkbox" name={"en_"+i.toString()} 
                    checked={this.state.formConfig.ch[i].en} onChange={this.handleChangeConfig} /></td>
                  <td className="has-text-centered"><input type="checkbox" name={"png_"+i.toString()} 
                    checked={this.state.formConfig.ch[i].png} onChange={this.handleChangeConfig} /></td>
                  <td className="has-text-centered"><input type="radio" name={"fmt_"+i.toString()} value={"1"}
                    checked={this.state.formConfig.ch[i].fmt===1} onChange={this.handleChangeConfig} /></td>
                  <td className="has-text-centered"><input type="radio" name={"fmt_"+i.toString()} value={"2"}
                    checked={this.state.formConfig.ch[i].fmt===2} onChange={this.handleChangeConfig} /></td>
                  <td className="has-text-centered"><input type="radio" name={"fmt_"+i.toString()} value={"0"}
                    checked={this.state.formConfig.ch[i].fmt===0} onChange={this.handleChangeConfig} /></td>                  
                  <td className="has-text-centered"><input className="input" type="number" name={"tpp_"+i.toString()} 
                    value={this.state.formConfig.ch[i].tpp} onChange={this.handleChangeConfig} /></td>  
                  <td className="has-text-centered"><input type="checkbox" name={"updateRemain_"+i.toString()}
                    checked={this.state.remain[i].updateRemain} onChange={this.handleChangeRemain} /></td>               
                  <td className="has-text-centered"><input className="input" type="number" name={"remain_"+i.toString()}
                    value={this.state.remain[i].remain} onChange={this.handleChangeRemain} /></td>  
                </tr>
              )}
            </tbody>
          </table>
        </div>

      </form>
      <br />

      <div className="columns">
        <div className="column">
          {this.passwordState()===1 ?
            <button className="button is-fullwidth is-primary" disabled>Invalid Password</button> :            
            <button className="button is-fullwidth is-primary" onClick={() => this.handleSend()}>Send</button>
          }
        </div>
        <div className="column">
          <button className="button is-fullwidth is-warning" 
            onClick={() => this.handleCancel()}>Cancel</button>
        </div>
      </div>
      </Fragment>
      );
    }
  }
