import React, { Component } from 'react';
import { Auth } from 'aws-amplify';

export default class Navbar extends Component {

  handleLogOut = async event => {
    event.preventDefault();
    try {
      Auth.signOut();
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
    }catch(error) {
      console.log(error.message);
    }
  }
  
  render() {
    return (
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img src="SmallLogo.png" width="89" height="38" alt="kealow logo" />
          </a>
        </div>
        <div className="navbar-item has-dropdown">
      </div>

        <div id="navbarBasicExample" className="navbar-menu">
                
          <div className="navbar-start">
            {this.props.auth.isAuthenticated && this.props.auth.user && (
              <div className="navbar-item">
              <div className="navbar-item dropdown has-dropdown is-hoverable">
                    <div className="navbar-link">Timers</div>
                    <div className="dropdown-menu" id="dropdown-menu" role="menu">
                        <div className="dropdown-content">
                          <a href="/" className="navbar-item is-light">Home</a>
                          <a href="/products" className="navbar-item is-light">View</a>
                          <a href="/onboard" className="navbar-item is-light">Onboard</a>
                        </div>
                    </div>
                </div>
              </div>
            )}
          </div>          

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                {!this.props.auth.isAuthenticated && (
                  <div>
                    <a href="/register" className="button is-primary">
                      <strong>Register</strong>
                    </a>
                    <a href="/login" className="button is-light">
                      Log in
                    </a>
                  </div>
                )}
                {this.props.auth.isAuthenticated && (
                    <a href="/" onClick={this.handleLogOut} className="button is-light">
                      Log out
                    </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}
