import React, { Component, Fragment } from 'react';
import axios from "axios";
import ConfigForm from './ConfigForm';
const config = require('../config.json');

export default class Products extends Component {
  constructor(props) {
    super(props);      
    var count = 0;
    if (this.props.auth.isAuthenticated && this.props.auth.user) {
      const groups = this.props.auth.user.signInUserSession.idToken.payload['cognito:groups'];   
      if (groups) count = groups.length;
    }
    this.state = {
      groups: [],
      focusId : null,
      nGroups: count
    }    
  }

  loseFocus() {
    this.setState({focusId: null});
  }
  
  setFocus= async (newFocus) => {    
    this.setState({ focusId : newFocus });
  }

  diff(now) {        
      const d = new Date();
      const n = d.getTime();
      const r = parseInt(now, 10);
      return (n - r) / 1000; // In seconds
  }

  diffString(now) {       
    let diff = this.diff(now); 
      let diffs = "";        
      if (diff < 60) {
          diffs = "less than a minute";
      } else {
          if (diff < 3600) {
              diffs = Math.round(diff/60).toString() + " minute(s)"
          } else {
              if (diff < 24*3600) {
                  diffs = Math.round(diff/3600).toString() + " hour(s)"
              } else {
                  if (diff < 365*24*3600) {
                      diffs = Math.round(diff/(24*3600)).toString() + " day(s)"
                  } else {
                      diffs = "over a year"
                  }
              }
          }
      }
      return diffs;
  }

  appendGroup = async (newGroup) => {
    const i = this.state.groups.findIndex(element => element.id === newGroup.id);
    let vGroups = [];    
    if (i===-1) {
      vGroups = [...this.state.groups, newGroup];
    } else {
      vGroups = [...this.state.groups];
      vGroups[i] = newGroup;
    }
    // sort by name
    vGroups.sort(function(a, b) {
      var nameA = a.id.toUpperCase(); // ignore upper and lowercase
      var nameB = b.id.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    this.setState({ groups: vGroups })
  } 

  fetchProducts = async () => {    
    if ((this.state.nGroups > 0) && this.props.auth.isAuthenticated && this.props.auth.user) {
      const groups = this.props.auth.user.signInUserSession.idToken.payload['cognito:groups'];   
      groups.forEach(element => {
        const data = { "group" : element };
        axios
          .post(config.api.invokeUrl, data)
          .then((response) => {
            this.appendGroup({
                "id"   :element,
                "count":response.data.Count,
                "items":response.data.Items
              });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  }

  componentDidMount = () => {
    this.fetchProducts();
    setInterval(this.fetchProducts, 5000); // runs every 5 seconds
  }

  render() {   
      return (
          <section className="section">
            <div className="container">
              {
                (this.props.auth.isAuthenticated && this.props.auth.user) 
                ? 
                <Fragment>
                <h1>Welcome {this.props.auth.user.username}</h1>
                <br />
                <div className="rows">
                  <div className="row">
                        { 
                          this.state.groups && this.state.groups.length > 0
                          ? this.state.groups.map(group => 
                            <div key={group.id} className="container">
                              <p className="product-title">Group: { group.id }</p>
                              { 
                                group.items && group.items.length > 0
                                ? group.items.map(item =>  

                                  <div key={item.id} className="container">                                      
                                    {this.state.focusId && this.state.focusId === item.id 
                                      ? 
                                      <Fragment>
                                          <button type="submit" 
                                            className={`button wide ${
                                              item.pending ? "is-warning" : (this.diff(item.refreshed) < 35*60 ? "is-success" : "is-danger") 
                                            } is-normal`}
                                            onClick={() => this.loseFocus()}
                                            >{item.timerName}</button>
                                          <div className="wide bottom-pad" style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>
                                            <div className="has-text-centered">
                                              <p>Last refreshed {this.diffString(item.refreshed)} ago {item.pending ? ". Pending change" : ""}</p>
                                            </div>
                                            <ConfigForm 
                                              id={this.state.focusId} 
                                              auth={this.props.auth}
                                              refreshParent={this.fetchProducts} />
                                          </div>
                                      </Fragment>
                                      : <button type="submit" 
                                      className={`button wide bottom-pad ${
                                        typeof item.certinfo !== 'undefined'
                                        ? (item.pending 
                                            ? "is-warning" 
                                            : (this.diff(item.refreshed) < 35*60 
                                              ? "is-success" 
                                              : "is-danger"))
                                        : "is-dark"
                                      } is-normal` }
                                      disabled={ typeof item.certinfo === 'undefined' } 
                                      onClick={() => this.setFocus(item.id)}
                                      >{item.timerName}</button>}
                                  </div>
                                  )
                                : <p>There are no timers in this group</p>
                                
                              }
                            </div>
                            )
                          : <div className="tile notification is-warning">{
                            this.state.nGroups > 0 ? `Loading... Please wait` : `You are not a member of any groups. Please contact your administrator`
                            }</div>
                        }
                  </div>
                </div>
                </Fragment>
                : <div className="tile notification is-warning">You are not logged in</div>
              }
            </div>
          </section>
      )
  }
}
