import React, { Component, Fragment } from 'react';
import axios from "axios";
const config = require('../config.json');

export default class Onboard extends Component {
  constructor(props) {
    super(props);         
    var groups = [];
    if (this.props.auth.isAuthenticated && this.props.auth.user) {
      const cognitoGroups = this.props.auth.user.signInUserSession.idToken.payload['cognito:groups'];   
      if (cognitoGroups) groups = cognitoGroups;
    }
    const group = (groups.length === 1) ? groups[0] : "";
    this.state = {
      groups      : groups,
      name        : "",
      group       : group,
      groupWarn   : false,
      nameWarn    : false,
      uniqueWarn  : false,
      tryResponse : ""
    }    
    console.log(this.state);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  makeTry = async (name, group) => {    
    if (this.props.auth.isAuthenticated && this.props.auth.user) {
      const data = { 
        "tryName"  : name,
        "tryGroup" : group
      };
      axios
        .post(config.api.invokeUrl, data)
        .then((response) => {
          console.log(response.data);
          var message = response.data.message ? response.data.message : "No response. Try again";
          this.setState({
            tryResponse : message});
          })
        .catch((error) => {
          console.log(error);
        });
      }
    }

  handleSubmit(event) {
    this.setState({
      groupWarn  : false,
      nameWarn   : false
    });
    console.log(this.state);
    if (this.state.group === "") {
      this.setState({
        groupWarn  : true
      });
    }
    if ((this.state.name.length <1)|(this.state.name.length >40)) {
      this.setState({
        nameWarn  : true
      });
    } 
    if(!this.state.groupWarn && !this.state.nameWarn) {
      console.log("Here!");
      this.makeTry(this.state.name, this.state.group);
    }
    event.preventDefault();
  }

  handleChange(event) { // Always text
    const target = event.target;
    let value = target.value;
    const name = target.name;
    this.setState({[name]: value});  
    event.preventDefault();
  }

  render() {   
      return (
          <section className="section">
            <div className="container">
              {
                (this.props.auth.isAuthenticated && this.props.auth.user) 
                ? 
                <Fragment>
                <h1>Welcome {this.props.auth.user.username}</h1>
                <br />
                {this.state.groups.length === 0 
                ? <div className="tile notification is-warning"
                  >You are not a member of any groups. Please contact your administrator</div>
                : (
                  <Fragment>                    
                    <form onSubmit={this.handleSubmit}>
                    <p>This page is used to onboard Timers that are not already registered</p>
                    <br />
                    {this.state.groups.length === 1
                      ? <p>You are a member one group: {this.state.groups[0]}</p>
                      : <Fragment>
                          <label>Choose a group to add the Timer to: </label>
                          <select value={this.state.group} name="group" onChange={this.handleChange}>
                            <option value="">----</option>   
                            {this.state.groups.map(group => 
                                <option key={group} value={group}>{group}</option>   
                            )}
                          </select>
                        </Fragment>
                    }
                    {this.state.groupWarn 
                      ? <p><b>You must select the group you want the timer to belong to</b></p>
                      : <p></p>
                    }
                    <br />
                    <label>Choose a name for the new Timer: </label>
                    <input name="name" type="text" maxLength="40"
                      value={this.state.name} onChange={this.handleChange} />
                    <br />
                    {this.state.nameWarn 
                      ? <p><b>You must choose a name with between 1 and 40 characters</b></p>
                      : <p></p>
                    }
                    <p>Important: The Timer name must not be the same as any other in the group</p>
                    <br />
                    <input type="submit" value="Submit" />
                    </form>
                    {this.state.tryResponse.length > 0 
                    ? <p><b>{this.state.tryResponse}</b></p>
                    : <p></p>                      
                    }
                  </Fragment> )
                }                
                </Fragment>
                : <div className="tile notification is-warning">You are not logged in</div>
              }
            </div>
          </section>
      )
  }
}
