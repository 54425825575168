import React from 'react'

export default function Footer() {
  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <p>
          This website and its content is copyright of Kealow Ltd - &copy; 2021. All rights are reserved.
        </p>
        <p>
          Contact us at <a href="mailto:kealow@gmx.com">kealow@gmx.com</a>.
        </p>
      </div>
    </footer>
  )
}
